<template>
  <v-card>
    <v-card-text>
      <!-- Description -->
      <!-- <h1>ใบสั่งซื้อ</h1> -->
      <div class="d-flex test2">
        <div class="test">
          <!-- ถึงกำหนดรับสินค้า -->
          <div>
            <div>
              <p class="mb-0 text-center font-weight-bold">ภ.ง.ด.3</p>
              <div class="me-2 box_content box-1">
                <div class="d-flex justify-center">
                  <p class="mb-0 me-1">
                    {{
                      this.$store.state.draft.amount +
                        this.$store.state.notpaid.amount +
                        this.$store.state.paid.amount +
                        this.$store.state.makeSend.amount +
                        this.$store.state.send.amount +
                        this.$store.state.expire.amount +
                        this.$store.state.cancel.amount
                    }}
                  </p>
                  <p class="mb-0">รายการ</p>
                </div>
                <p class="mb-0">฿ 100.00</p>
              </div>
            </div>
          </div>
          <!-- รับสินค้าภายใน 7 วัน -->
          <div>
            <p class="mb-0 text-center font-weight-bold">ภ.ง.ด.53</p>
            <div class="me-2 box_content box-2">
              <div class="d-flex justify-center">
                <p class="mb-0 me-1">{{ this.$store.state.draft.amount }}</p>
                <p class="mb-0">รายการ</p>
              </div>
              <p class="mb-0">฿ 0</p>
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res" @click="gotoTax">
            <v-icon class="me-2">
              {{ icons.mdiPlusCircle }}
            </v-icon>
            สร้างใบหัก ณ ที่จ่าย
          </v-btn>
        </div>
      </div>
      <v-row class="mt-5">
        <v-col md="4" sm="6" cols="12">
          <v-text-field
            :prepend-inner-icon="icons.mdiMagnify"
            label="ค้นหา"
            type="text"
            outlined
            dense
            hide-details
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-select
            :prepend-inner-icon="icons.mdiFilter"
            dense
            outlined
            hide-details
            label="ตัวกรอง"
            :items="filter"
          ></v-select>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-select
            :prepend-inner-icon="icons.mdiFileDocument"
            dense
            hide-details
            outlined
            label="ใบสำคัญจ่าย"
            :items="filterBill"
          ></v-select>
        </v-col>
      </v-row>
      <!-- ตารางสถานะต่าง ๆ -->
      <v-data-table
        dense
        show-select
        :selectable-key="id"
        :headers="headers"
        :items="billData"
        item-key="name"
        class="elevation-1 mt-5"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiCartMinus,
  mdiMagnify,
  mdiTruck,
  mdiPrinterSearch,
  mdiPlusCircle,
  mdiFilter,
  mdiFileDocument,
} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: { DatePicker },
  setup() {
    const filter = ['แบบร่าง', 'อนุมัติ', 'ยืนยันสั่งซื้อ', 'ยกเลิก', 'รับสินค้าบางส่วน', 'รับสินค้าครบแล้ว']
    const filterBill = ['ใบสำคัญจ่าย', 'ชื่อซัพพลายเออร์', 'สินค้า', 'ชื่อโปรเจกต์', 'โน็ต', 'เลขที่ใบกำกับภาษี']

    return {
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
        mdiPlusCircle,
        mdiFilter,
        mdiFileDocument,
      },
      filter,
      filterBill,
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      select: [],
      headers: [
        {
          text: 'ใบหัก ณ ที่จ่าย',
          align: 'start',
          sortable: false,
          value: 'billTax',
        },
        { text: 'สถานะ', value: 'status' },
        { text: 'ซัพพลายเออร์', value: 'supplier' },
        { text: 'สินค้า', value: 'product' },
        { text: 'แบบ ภงด.', value: 'type' },
        { text: 'หัก ณ ที่จ่าย', value: 'tax' },
        { text: 'ยอดหัก', value: 'subTax' },
        { text: 'วันที่', value: 'date' },
      ],
      billData: [
        {
          id: 'wyw1',
          billTax: '-',
          status: 'อนุมัติ',
          supplier: 'บจก. A จำกัด',
          product: 'กระเบื้องเซรามิก',
          type: '-',
          tax: 140000,
          subTax: 146000,
          date: '30/7/2022 18:00',
        },
        {
          id: 'wyw2',
          billTax: '-',
          status: 'อนุมัติ',
          supplier: 'บจก. B จำกัด',
          product: 'สีทาบ้าน TOA',
          type: '-',
          tax: 110000,
          subTax: 120000,
          date: '30/7/2022 18:00',
        },
      ],
    }
  },
  methods: {
    gotoTax() {
      const taxPage = this.$router.resolve({ path: '/pages/widthHolder' })
      window.open(taxPage.href)
    },
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
  },
}
</script>

<style scoped>
.box_content {
  text-align: center;
  background-color: #f4f5f9;
  display: grid;
  align-items: center;
  padding: 10px 0;
  color: #8e8a98;
  font-weight: 600;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.box_content.box-1 {
  background-color: #8dbc89;
  color: #ffffff;
  width: 21rem;
  height: 5rem;
}
.box_content.box-2 {
  background-color: dodgerblue;
  color: #ffffff;
  width: 21rem;
  height: 5rem;
}
header {
  background-color: #8dbc89;
}
.bg-header p {
  color: #f4f5f9;
  font-weight: 600;
  font-size: 16px;
}
.bg-header span {
  color: #fff8ff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  text-decoration: underline;
}
.checkItem {
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}
.picker-res {
  width: 100% !important;
}
.test {
  display: flex;
}
.test2 {
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  /* .test {
    display: flex;
    justify-content: center;
  } */
  .test2 {
    display: grid !important;
    justify-content: center;
  }
  .mg-res {
    margin-top: 1.5rem;
    transform: translateX(-50%);
    left: 50%;
  }
  /* .picker-res {
    width: 100% !important;
  } */
  .box_content.box-1 {
    width: 7rem !important;
  }
  .box_content.box-2 {
    width: 7rem !important;
  }
}

@media screen and (max-width: 960px) {
  .box_content.box-1 {
    width: 10rem;
  }
  .box_content.box-2 {
    width: 10rem;
  }
}
</style>
